import React from 'react';
import Helmet from '../project/Helmet';
import CustomersDisplay from './customers/CustomersDisplay';
import ListWithTableLayout from '../project/ListWithTableLayout';
import {Trans, t} from '@lingui/macro';
import CustomersParams from './customers/CustomersParams';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { getQueryParamNumber } from '../../lib/url';
import { QUERY_PARAMS } from '../../constants/navigation';
import CustomerDetailsContent from './customerDetails/CustomerDetailsContent';
import locationHOC from '../locationProvider/locationHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import withDataHOC from '../dataProvider/withDataHOC';
import UsersTable from './customers/UsersTable';

/**
 * @dusan
 */

class CustomersPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: undefined,
        };
    }

    componentDidMount() {
        this.setState({activeTab: 'list'});
    }

    setActiveTab = (key) => {
        this.setState({activeTab: key});
    }

    render() {
        const {activeTab} = this.state;
        return <React.Fragment>
            <Helmet
                title={t`Organizácie`}
            />
            <ListWithTableLayout
                title={<Trans>Organizácie</Trans>}
                list={<CustomersDisplay/>}
                table={<CustomersMainTabWrapped/>}
                listTitle={<Trans>Všetky</Trans>}
                tableTitle={<Trans>Kontakty</Trans>}
                activeTab={activeTab}
                setActiveTab={this.setActiveTab}
            />
        </React.Fragment>;
    }

}

export default CustomersPage;

class CustomersMainTab extends React.PureComponent {
    componentDidMount() {
        this.fetchCustomerDetails();
    }

    shouldShowCustomerDetails = () => {
        const {location, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_PAGE_CUSTOMER);
        return (customerId != null && !isMobile);
    }

    fetchCustomerDetails = () => {
        const {location, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_PAGE_CUSTOMER);
        if(customerId == null || isMobile)
            return; // do nothing

        // force loading of data, they are not bound to this route
        reload([
            GLOBAL_DATA.CUSTOMER_DETAILS,
            GLOBAL_DATA.CUSTOMER_DETAILS_ADDRESSES,
            GLOBAL_DATA.CUSTOMER_DETAILS_USERS, 
        ]);
    }

    componentDidUpdate(prevProps) {
        const {location} = this.props;
        const {location: prevLocation} = prevProps;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_PAGE_CUSTOMER);
        const prevCustomerId = getQueryParamNumber(prevLocation, QUERY_PARAMS.ID_PAGE_CUSTOMER);
        
        if(customerId != prevCustomerId && customerId != null)
            this.fetchCustomerDetails();
    }

    render() {
        if(this.shouldShowCustomerDetails())
        {
            return <CustomerDetailsContent/>;
        }
        else
        {
            return <TableWithFiltersLayout
                title={<Trans>Kontakty</Trans>}
                params={<CustomersParams/>}
                table={<UsersTable/>}
                scrollParam={QUERY_PARAMS.USERS_SCROLL}
            />;
        }
    }
}

const CustomersMainTabWrapped = locationHOC(
    withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(
        withDataHOC([GLOBAL_DATA.RELOAD_DATA])(
            CustomersMainTab
        )
    )
);