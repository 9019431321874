import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import InputText from '../../general/InputText';
import EmployeesSelect from '../../project/EmployeesSelect';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import {getQueryParam, createSetQueryParamValue, navigateToParametrized, getQueryParamNumber} from '../../../lib/url';
import {getInputOnChangeEventChecked, getInputOnChangeEventValue} from '../../../lib/project';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import { Button, Checkbox, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import CustomerTypesSelect from '../../project/CustomerTypesSelect';
import InputQuantity from '../../project/InputQuantity';
import CustomerGroupSelect from '../../project/CustomerGroupSelect';

/**
 * @dusan
 */

class CustomersParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.CUSTOMER_USER_PHRASE]: undefined,
            [QUERY_PARAMS.CUSTOMER_SHOW_HIDDEN]: undefined,
            [QUERY_PARAMS.CUSTOMER_ID_SALESMAN]: undefined,
            [QUERY_PARAMS.CUSTOMER_ID_SALESMAN2]: undefined,
            [QUERY_PARAMS.CUSTOMER_ID_TYPE]: undefined,
            [QUERY_PARAMS.CUSTOMER_AUTO_INVOICE]: undefined,
            [QUERY_PARAMS.CUSTOMER_PAYMENT_DUE]: undefined,
            [QUERY_PARAMS.CUSTOMER_ID_GROUP]: undefined,
            [QUERY_PARAMS.USERS_NEWSLETTER]: undefined,
            [QUERY_PARAMS.USERS_ORDER_BY]: undefined,
            [QUERY_PARAMS.USERS_SCROLL]: undefined,
            [QUERY_PARAMS.ID_PAGE_CUSTOMER]: undefined,
            [QUERY_PARAMS.USER_DETAILS_ID_USER]: undefined,
            [QUERY_PARAMS.CUSTOMER_USERS_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
        });
    };

    render() {
        const {location} = this.props;
        const phrase = getQueryParam(location, QUERY_PARAMS.CUSTOMER_USER_PHRASE);
        const showHidden = getQueryParamNumber(location, QUERY_PARAMS.CUSTOMER_SHOW_HIDDEN);
        const salesmanId = getQueryParamNumber(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN);
        const salesman2Id = getQueryParamNumber(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN2);
        const typeId = getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_TYPE);
        const groupId = getQueryParamNumber(location, QUERY_PARAMS.CUSTOMER_ID_GROUP);
        const autoInvoice = getQueryParamNumber(location, QUERY_PARAMS.CUSTOMER_AUTO_INVOICE);
        const paymentDue = getQueryParam(location, QUERY_PARAMS.CUSTOMER_PAYMENT_DUE);
        const newsletter = getQueryParamNumber(location, QUERY_PARAMS.USERS_NEWSLETTER);

        const hasFilter = phrase != null || showHidden != null || salesmanId != null || salesman2Id != null || 
            typeId != null || autoInvoice != null || paymentDue != null || groupId != null || newsletter != null;

        return <ColumnFilterLayout
            defaultCollapsed={true}
            filters={[
                [
                    {
                        label: <Trans>Zák. skupina: </Trans>,
                        selector: <CustomerGroupSelect
                            value={groupId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.CUSTOMER_ID_GROUP)}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Hľadať:</Trans>,
                        selector: <InputText
                            value={phrase}
                            placeholder={"názov, IČO, e-mail..."}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.CUSTOMER_USER_PHRASE)}
                        />,
                    },
                    {
                        label: <Trans>Odber noviniek:</Trans>,
                        selector: <Radio.Group
                            onChange={(e) => {
                                const value = getInputOnChangeEventValue(e);
                                createSetQueryParamValue(location, QUERY_PARAMS.USERS_NEWSLETTER)(value);
                            }}
                            value={newsletter}
                        >
                            <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                            <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                        </Radio.Group>
                    },
                    {
                        selector: <div className="d-flex justify-content-end">
                            <Checkbox
                                checked={showHidden != null && showHidden > 0}
                                onChange={(ev) => {
                                    const value = getInputOnChangeEventChecked(ev) ? 1 : undefined;
                                    navigateToParametrized(location, null, {[QUERY_PARAMS.CUSTOMER_SHOW_HIDDEN]: value});
                                }}
                            >
                                <Trans>zobraziť aj neaktívne organizácie</Trans>
                            </Checkbox>
                        </div>
                    }
                ],
                [
                    {
                        label: <Trans>Zodp. obchodník: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesmanId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN)}
                            allowClear={true}
                        />,
                    },
                    {
                        label: <Trans>Logista: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesman2Id}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN2)}
                            allowClear={true}
                        />,
                    },
                    {
                        label: <Trans>Typ organizácie: </Trans>,
                        selector: <CustomerTypesSelect
                            value={typeId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.CUSTOMER_ID_TYPE)}
                            allowClear={true}
                        />
                    },
                ],
                [
                    {
                        label: <Trans>Automatická fakturácia: </Trans>,
                        selector: <Radio.Group
                            onChange={(e) => {
                                const value = getInputOnChangeEventValue(e);
                                createSetQueryParamValue(location, QUERY_PARAMS.CUSTOMER_AUTO_INVOICE)(value);
                            }}
                            value={autoInvoice}
                        >
                            <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                            <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                        </Radio.Group>
                    },
                    {
                        label: <Trans>Splatnosť: </Trans>,
                        selector: <InputQuantity
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.CUSTOMER_PAYMENT_DUE)}
                            value={paymentDue}
                            min={0}
                            unit={<Trans>dní</Trans>}
                            allowClear={true}
                        />,
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            onClick={this.clearAllParams}
                            className="full-size-width"
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>,
                    }
                ],
            ]}
        />;
    }

}

export default locationHOC(CustomersParams);