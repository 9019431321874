import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../../fetch/FormModal';
import AddUserForm from './form/AddUserForm';
import generalFailedPC from '../../../fetch/generalFailedPC';
import locationHOC from '../../../locationProvider/locationHOC';
import Tooltip from '../../../general/Tooltip';
import {navigateToParametrized} from '../../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../../constants/navigation';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class AddUserDetail extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    render() {
        const {location} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Pridanie kontaktu</Trans>}>
                    <Button size="small" icon="plus"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={(userId) => {
                navigateToParametrized(location, ROUTES.CUSTOMERS_USER_DETAILS, {[QUERY_PARAMS.USER_DETAILS_ID_USER]: userId})
            }}
            title={<Trans>Pridanie kontaktu</Trans>}
            Form={AddUserForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa pridať kontakt.`)}
        />;
    }
}

export default locationHOC(AddUserDetail);