import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import CustomersList from './display/CustomersList';
import SearchByLocation from '../../general/SearchByLocation';
import AddCustomer from './display/AddCustomer';
import VisibilitySelector from './display/VisibilitySelector';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Customer} from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */

class CustomersDisplay extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.CUSTOMER_LIST]: PropTypes.arrayOf(Customer.isRequired).isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const customers = this.props[GLOBAL_DATA.CUSTOMER_LIST];
        return <div className="full-size-height d-flex flex-column">
            <div className="flex-item-static">
                <SearchByLocation
                    className="full-size-width"
                    textQueryParamKey={QUERY_PARAMS.CUSTOMER_USER_PHRASE}
                    additionalParams={{
                        [QUERY_PARAMS.ID_PAGE_CUSTOMER]: undefined,
                        [QUERY_PARAMS.USERS_ORDER_BY]: undefined,
                        [QUERY_PARAMS.USERS_SCROLL]: undefined,
                        [QUERY_PARAMS.USERS_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
                    }}
                />
            </div>
            <div className="pt-2 flex-item-static">
                <AddCustomer/>
            </div>
            <div className="py-2 px-3">
                <VisibilitySelector/>
            </div>
            <div className="pt-2 flex-item-dynamic-1 overflow-hidden">
                <CustomersList customers={customers}/>
            </div>
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.CUSTOMER_LIST])(CustomersDisplay);