import React from 'react';
import {Trans, t} from '@lingui/macro';
import {Checkbox} from 'antd';
import locationHOC from '../../../locationProvider/locationHOC';
import {navigateToParametrized, getQueryParam} from '../../../../lib/url';
import {QUERY_PARAMS} from '../../../../constants/navigation';

/**
 * @fero
 */

class VisibilitySelector extends React.PureComponent {

    onVisibilityChanged = (ev) => {
        const {location} = this.props;
        const showHidden = ev.target.checked ? 1 : null;
        navigateToParametrized(location, null, {[QUERY_PARAMS.CUSTOMER_SHOW_HIDDEN]: showHidden});
    };

    render() {
        const {location} = this.props;
        const showHidden = getQueryParam(location, QUERY_PARAMS.CUSTOMER_SHOW_HIDDEN);
        return <Checkbox
            onChange={this.onVisibilityChanged}
            checked={showHidden ? true : false}
        >
            <Trans>zobraziť aj neaktívne organizácie</Trans>
        </Checkbox>;
    }

}

export default locationHOC(VisibilitySelector);