import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import Table from '../../general/Table';
import UsersRow from './table/UsersRow';
import AddUserDetail from './table/AddUserDetail';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {User} from '../../../constants/propTypesDefinitions';
import {navigateToParametrized, getQueryParam} from '../../../lib/url';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class UsersTable extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.USERS]: PropTypes.arrayOf(User.isRequired).isRequired,
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.USERS_LIMIT]: newLimit,
        });
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.USERS_ORDER_BY]: newOrder,
            [QUERY_PARAMS.USERS_SCROLL]: 0,
        });
    };

    render() {
        const {location} = this.props;
        const userDetails = this.props[GLOBAL_DATA.USERS];
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const orderBy = getQueryParam(location, QUERY_PARAMS.USERS_ORDER_BY);
        const currentLimit = getQueryParam(location, QUERY_PARAMS.USERS_LIMIT);
        const currentLimitChecked = currentLimit != null ? Number(currentLimit) : 20;

        return <Table
            className="user-table"
            BodyRow={UsersRow}
            data={userDetails}
            pageSize={!Number.isNaN(Number(process.env.DEFAULT_TABLE_PAGE_SIZE)) ? Number(process.env.DEFAULT_TABLE_PAGE_SIZE) : 20}
            limit={currentLimitChecked}
            orderBy={orderBy}
            changeOrder={this.setOrdering}
            changeLimit={this.setLimit}
            reload={() => {
                reload([GLOBAL_DATA.USERS])
            }}
            colDefs={[
                {
                    headerCell: <Trans>Meno a priezvisko</Trans>,
                    orderCol: 'name',
                    class: 'user-table-col-fullname'
                },
                {
                    headerCell: <Trans>E-mail</Trans>,
                    orderCol: 'e_mail',
                    class: 'user-table-col-email'
                },
                {
                    headerCell: <Trans>Telefón</Trans>,
                    orderCol: 'phone',
                    class: 'user-table-col-phone'
                },
                {
                    headerCell: <Trans>Organizácia</Trans>,
                    orderCol: 'customer',
                    class: 'user-table-col-customer'
                },
                {
                    headerCell: <Trans>Práva</Trans>,
                    orderCol: 'access_level',
                    class: 'user-table-col-access-level'
                },
                {
                    headerCell: <div className="d-flex justify-content-center"><AddUserDetail/></div>,
                    class: 'user-table-col-actions'
                },
            ]}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.USERS, GLOBAL_DATA.RELOAD_DATA])(UsersTable));